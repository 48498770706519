import React from 'react';

import { Loading } from '@/components';
import { DataTable } from '@/components/DataTable/DataTable';
import { ErrorUi } from '@/components/ErrorUi/ErrorUi';
import { OHiddenWrapper } from '@/components/OHiddenWrapper/OHiddenWrapper';
import { PageTitle } from '@/components/PageTitle/PageTitle';
import { showResponseStatusCode } from '@/helpers/showResponseStatusCode';
import { withLayout } from '@/layout/Layout';
import { useGetPrintJobsQuery } from '@/store/queries/printJobApi';

import { columns } from './data';

const PrintJobPage = () => {
  const {
    isLoading,
    data = [],
    isError,
    error,
    refetch,
  } = useGetPrintJobsQuery();

  return (
    <OHiddenWrapper className="gap-y-4">
      <PageTitle title="Работы печати" />

      <ErrorUi
        onRefetch={refetch}
        isError={isError}
        title={`${showResponseStatusCode(error)}`}
      />

      {isLoading && <Loading isLoading={isLoading} title="Заказы" />}

      <DataTable
        searchFieldId="Пользователь"
        searchPlaceholder="Поиск по пользователю"
        columns={columns}
        data={data}
      />
    </OHiddenWrapper>
  );
};

export default withLayout(PrintJobPage);
