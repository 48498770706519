import { WidgetIcon } from '@/components/Dashboard/WidgetIcon/WidgetIcon';
import { type WidgetId } from '@/global/dashboard.interface';
import { CardHeader, CardTitle } from '@/shared/ui/Card/Card';

interface WidgetCardHeaderProps {
  title?: string;
  type?: WidgetId;
}

export const WidgetCardHeader = ({ title, type }: WidgetCardHeaderProps) => {
  return (
    <CardHeader className="flex flex-row items-center justify-between border-b py-2.5 gap-5 bg-muted">
      <CardTitle className="text-sm font-medium">{title}</CardTitle>

      <WidgetIcon type={type} />
    </CardHeader>
  );
};
