import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Moon, Sun } from 'lucide-react';

import { Button } from '@/shared/ui/Button/Button';
import { useTypedSelector } from '@/store/hooks/hooks';
import { setTheme } from '@/store/slices/themeSlice';

export const ToggleTheme = () => {
  const activeTheme = useTypedSelector((state) => state.theme);
  const dispatch = useDispatch();

  useEffect(() => {
    document.documentElement.dataset.theme = activeTheme;
    document.documentElement.className = activeTheme;
    localStorage.setItem('theme', activeTheme);
  }, [activeTheme]);

  const toggleTheme = () => {
    dispatch(setTheme(activeTheme === 'dark' ? 'light' : 'dark'));
  };

  return (
    <Button
      onClick={toggleTheme}
      // className="w-10 h-10"
      variant="outline"
      size="icon"
    >
      <Sun className="h-4 w-4 rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
      <Moon className="absolute h-4 w-4 rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100 stroke-white" />
    </Button>
  );
};
