import { type SerializedError } from '@reduxjs/toolkit';
import { type FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

export const showResponseStatusCode =
  (error: FetchBaseQueryError | SerializedError | undefined): string => {
    if (error && 'status' in error) {
      if (error.status === 403) {
        return 'Нет прав на просмотр данной страницы';
      }
      return `Произошла ошибка при загрузке (код ${String(error.status)})`;
    }

    return '';
  };
