import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import cn from 'classnames';
import { ChevronLeft } from 'lucide-react';

import { Table } from '@/components';
import { DataTable } from '@/components/DataTable/DataTable';
import { LineChart } from '@/components/LineChart/LineChart';
import { PageTitle } from '@/components/PageTitle/PageTitle';
import type { IPrinter } from '@/global/types';
import { getDate } from '@/helpers/date';
import { withLayout } from '@/layout/Layout';
import { Badge } from '@/shared/ui/Badge/Badge';
import { Button } from '@/shared/ui/Button/Button';
import { DatePicker } from '@/shared/ui/DatePicker/DatePicker';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/shared/ui/Tabs/Tabs';
import {
  useGetDevicesQuery,
  useGetReplacementsQuery,
} from '@/store/queries/printerApi';

import { alertColumns, columns, replacementColumns } from './data';
import { useChart } from './hooks';

import styles from './PrinterPage.module.css';

type Tab = 'charts' | 'warnings' | 'replacements';

const Printer: React.FC = () => {
  const { id } = useParams();

  const [printer, setPrinter] = useState<IPrinter[]>([]);

  const [activeTab, setActiveTab] = useState<Tab>('charts');

  const [dateStart, setDateStart] = useState<Date | undefined>(
    getDate(20).toDate(),
  );
  const [dateEnd, setDateEnd] = useState<Date | undefined>(new Date());

  const { data: replacements = [], isLoading: replacementsLoading } =
    useGetReplacementsQuery(id as string);

  const { alerts, chart, chartLoading, chartData, getChartAndAlerts } =
    useChart(id, dateEnd, dateStart);

  useEffect(() => {
    getChartAndAlerts();
  }, []);

  const { data: devices = [], isLoading } = useGetDevicesQuery();

  console.log(devices);

  useEffect(() => {
    if (devices.length) {
      setPrinter(devices.filter((device) => device.id === Number(id)));
    }
  }, [devices]);

  const findOutIsChartEmpty = () => {
    if (!chart.length && !chartLoading) {
      return <span className={styles.empty}>Пусто</span>;
    }

    return '';
  };

  const findOutIsReplacementsEmpty = () => {
    if (!replacements.length && !replacementsLoading) {
      return (
        <span className="absolute text-muted-foreground left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
          Пусто
        </span>
      );
    }

    if (replacementsLoading) {
      return (
        <span className="absolute text-muted-foreground left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
          Загрузка
        </span>
      );
    }

    return '';
  };

  return (
    <div className="flex flex-col gap-6 h-full">
      <div className="space-y-4">
        <div className="flex items-center gap-4">
          <Button asChild size="sm" variant="outline">
            <Link to="/printers">
              <ChevronLeft className="w-4 h-4" />
            </Link>
          </Button>

          <PageTitle title={isLoading ? 'Загрузка' : printer[0]?.name} />

          {!isLoading && printer[0] && (
            <Badge
              variant="outline"
              className={cn(
                printer[0]?.state ? 'text-green-600' : 'text-red-600',
              )}
            >
              {printer[0]?.state ? 'В сети' : 'Выключен'}
            </Badge>
          )}
        </div>

        <Table direction="vertical" data={printer} columns={columns} />
      </div>

      <Tabs
        value={activeTab}
        onValueChange={setActiveTab as (value: string) => void}
      >
        <TabsList>
          <TabsTrigger value="charts">Графики</TabsTrigger>
          <TabsTrigger value="warnings">Предупреждения</TabsTrigger>
          <TabsTrigger value="replacements">Замены</TabsTrigger>
        </TabsList>

        {activeTab !== 'replacements' && (
          <div className={styles.ranges}>
            <DatePicker onChange={setDateStart} date={dateStart} />
            <DatePicker onChange={setDateEnd} date={dateEnd} />

            <Button
              onClick={getChartAndAlerts}
              disabled={chartLoading || !dateEnd || !dateStart}
            >
              Поиск
            </Button>
          </div>
        )}

        <TabsContent value="charts">
          <div>
            <div className={styles.chartBlock}>
              <LineChart height={300} data={chartData} />

              {findOutIsChartEmpty()}
            </div>
          </div>
        </TabsContent>

        <TabsContent value="warnings">
          <DataTable
            tableContainerClassName="max-h-[370px]"
            showToolbar={false}
            columns={alertColumns}
            data={alerts}
          />
        </TabsContent>

        <TabsContent className="relative min-h-[420px]" value="replacements">
          <DataTable
            tableContainerClassName="max-h-[420px]"
            showToolbar={false}
            columns={replacementColumns}
            data={replacements}
          />

          {findOutIsReplacementsEmpty()}
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default withLayout(Printer);
