import { type ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

import { DataTableColumnHeader } from '@/components/DataTable/DataTableColumnHeader';
import { Badge } from '@/shared/ui/Badge/Badge';

import { type PrintJob } from '../../global/types';

export const columns: Array<ColumnDef<PrintJob>> = [
  {
    id: 'Дата',
    accessorKey: 'jobStartTime',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Дата" />
    ),
    cell: ({ row }) =>
      format(new Date(row.getValue('Дата')), 'dd.MM.yyyy HH:mm', {
        locale: ru,
      }),
  },
  {
    id: 'Файл',
    accessorKey: 'fileName',
    header: 'Файл',
  },
  {
    id: 'Пользователь',
    accessorKey: 'jobOwner',
    header: 'Пользователь',
  },
  {
    id: 'Хост',
    accessorKey: 'fromHost',
    header: 'Хост',
  },
  {
    id: 'Кол-во страниц',
    accessorKey: 'pageCount',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Кол-во страниц" />
    ),
  },
  {
    id: 'Статус',
    accessorKey: 'jobState',
    header: 'Статус',
    cell: ({ row }) => (
      <Badge variant="outline" className="text-[0.7rem]">
        {row.getValue('Статус')}
      </Badge>
    ),
  },
];
