import type {IQueue} from '@/global/types';

import { api } from './api';


export interface IQueueForm {
  queueName: string;
  direct: boolean;
  printer: number;
  active: boolean;
  type: number;
};

const queueApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getQueues: builder.query<IQueue[], void>({
      query: () => '/v2/queues/get-queues',
    }),
    createQueue: builder.mutation<IQueue, IQueueForm>({
      query: (data) => ({
        method: 'POST',
        url: '/v2/queues/create-queues',
        body: data
      })
    })
  }),
});

export const { useGetQueuesQuery,
  useCreateQueueMutation } = queueApi;
