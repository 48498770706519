import { type ChartData } from '@/components/Dashboard/PieChartWidget/PieChartWidget';

export const CustomTooltip = ({
  active,
  payload,
}: {
  active: boolean;
  payload: Array<{ payload: ChartData }>;
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-background shadow-md rounded-md py-3 px-5">
        <p className="text-sm">{payload[0].payload.name}</p>

        {payload[0].payload.value && (
          <p className="text-sm">{payload[0].payload.value}</p>
        )}
      </div>
    );
  }

  return null;
};
