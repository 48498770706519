import type { IParam, IParamOrig } from '@/global/types';

import { api } from './api';

const transformParam = (param: IParamOrig): IParam => {
  return {
    ...param,
    param_values:
      param.param_type === 'list' ? JSON.parse(param.param_value) : null,
    param_value: param.param_type !== 'list' ? param.param_value : null,
  };
};

const handleTransformParamsResponse = (res: IParamOrig[]): IParam[] => {
  const transformed: IParam[] = res.map((param) => transformParam(param));

  return transformed;
};

const paramApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getParams: builder.query<IParam[], void>({
      query: () => '/param/get-host-params',
      providesTags: ['PARAM'],
      transformResponse: handleTransformParamsResponse,
    }),
    updateParam: builder.mutation<IParam, IParamOrig>({
      query: (param) => ({
        url: '/param/update',
        method: 'POST',
        body: param,
      }),
      invalidatesTags: ['PARAM'],
      transformResponse: transformParam,
    }),
  }),
});

export const { useGetParamsQuery, useUpdateParamMutation } = paramApi;
