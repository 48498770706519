import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import { DeviceList, Filters, Loading, Render, View } from '@/components';
import { DataTable } from '@/components/DataTable/DataTable';
import { ErrorUi } from '@/components/ErrorUi/ErrorUi';
import { PageTitle } from '@/components/PageTitle/PageTitle';
import { type IPrinter } from '@/global/types';
import { showResponseStatusCode } from '@/helpers/showResponseStatusCode';
import { useFilter } from '@/hooks/useFilter';
import { withLayout } from '@/layout/Layout';
import { useGetDevicesQuery } from '@/store/queries/printerApi';

import { columns } from './columns';
import { type FilterFieldTypes, printersFilter } from './data';

type Views = 0 | 1;

const Printers: React.FC = () => {
  const [search, setSearch] = useState<string>('');
  const [view, setView] = useState<Views>(
    (Number(localStorage.getItem('view')) as Views) || 0,
  );
  const [filterOpen, setFilterOpen] = useState<boolean>(false);

  const {
    data: devices = [],
    isLoading,
    error,
    isError,
    refetch,
  } = useGetDevicesQuery();

  const { handleChange, activeFilters, resetFilters, filtered } = useFilter<
    IPrinter[],
    FilterFieldTypes
  >({ data: devices, searchValue: search });

  const closeFilters = useCallback(() => {
    setFilterOpen(false);
  }, []);

  const handleChangeView = useCallback((view: Views) => {
    setView(view);
    localStorage.setItem('view', String(view));
  }, []);

  return (
    <div className="flex flex-col h-full gap-4">
      <PageTitle title="Принтеры" />

      <ErrorUi
        onRefetch={refetch}
        isError={isError}
        title={`${showResponseStatusCode(error)}`}
      />

      <div className="h-full flex flex-col gap-2 overflow-y-hidden">
        {!isLoading && (
          <div className="flex flex-wrap gap-4 justify-between">
            <div className="flex items-center flex-wrap">
              <Filters
                filters={printersFilter}
                activeFilters={activeFilters}
                setSearch={setSearch}
                handleChange={handleChange}
              />
            </div>

            <View changeView={handleChangeView} view={view} />
          </div>
        )}

        <Loading isLoading={isLoading} title="Устройства" />

        <Render className="h-full overflow-y-scroll" render={view === 0}>
          <DataTable showToolbar={false} columns={columns} data={filtered} />
        </Render>

        <Render className="h-full overflow-y-scroll" render={view === 1}>
          <DeviceList onClick={closeFilters} data={filtered} />
        </Render>
      </div>
    </div>
  );
};

export default withLayout(Printers);
