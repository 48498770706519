import React from 'react';
import type { SxProps, TextFieldProps } from '@mui/material';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import 'dayjs/locale/ru';

import type { DPickerProps } from './DatePicker.props';

import styles from './DatePicker.module.css';

const MyTextField = styled(TextField)<TextFieldProps>`
  & fieldset {
    border-color: var(--select-border-color);
  }

  & svg {
    font-size: 16px;
  }

  [data-theme='dark'] &:hover fieldset {
    border-color: #6e7a8c;
  }

  [data-theme='dark'] & svg {
    color: var(--white);
  }

  & .MuiInputBase-root {
    width: 170px;
    height: 32px;
    color: var(--white);
    margin: 0;
    border-radius: 0;
  }
`;

const popperXs: SxProps = {
  '& .MuiPaper-root': {
    color: 'var(--white)',
    boxShadow: '0 2px 8px var(--select-box-shadow-color)',
    background: 'var(--whiteBackground)'
  },
  '& .MuiCalendarPicker-root': {
    color: 'var(--white)',
    backgroundColor: 'var(--whiteBackground)'
  },
  '& .MuiPickersDay-dayWithMargin': {
    border: 'none',
    color: 'var(--white)',
    backgroundColor: 'var(--whiteBackground2)'
  },
  '& .MuiPickersDay-dayWithMargin:not(.Mui-selected):hover': {
    background: 'var(--hover)'
  },
  "[data-theme='dark'] & .MuiDayPicker-header span": {
    color: 'var(--white)'
  },
  '& .MuiButtonBase-root': {
    color: 'var(--white)'
  },
  '& .MuiButtonBase-root svg': {
    color: 'var(--white)'
  },
  '& .MuiClockNumber-root': {
    color: 'var(--white)'
  },
  '& .MuiClockNumber-root.Mui-selected': {
    color: 'white'
  }
};

export const DatePicker: React.FC<DPickerProps> = ({
  label,
  value,
  setValue
}) => {
  return (
    <LocalizationProvider adapterLocale="ru" dateAdapter={AdapterDayjs}>
      <DateTimePicker
        className={styles.picker}
        label={label}
        value={value}
        PopperProps={{ sx: popperXs }}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => (
          <MyTextField
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: 'дд.мм.гггг чч:мм'
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
