import { type ColumnDef } from '@tanstack/react-table';
import cn from 'classnames';
import { format } from 'date-fns';

import type { IAlert, IColumn, Replacement } from '@/global/types';

export const columns: IColumn[] = [
  { title: 'ID аппарата', field: 'id' },
  { title: 'Серийный номер', field: 'sn' },
  { title: 'MAC адрес', field: 'mac', style: { textTransform: 'uppercase' } },
  { title: 'IP адрес', field: 'ip' },
  { title: 'Текущий счетчик', field: 'counter' },
];

export const alertColumns: Array<ColumnDef<IAlert>> = [
  {
    header: 'Появление',
    accessorKey: 'timeInsert',
    cell: ({ row }) => (
      <span className="whitespace-nowrap">
        {format(new Date(row.getValue('timeInsert')), 'dd.MM.yyyy HH:mm')}
      </span>
    ),
  },
  { header: 'Сообщение', accessorKey: 'alertDescription' },
  {
    header: 'Решение',
    accessorKey: 'alertEnd',
    cell: ({ row }) => (
      <span className="whitespace-nowrap">
        {format(new Date(row.getValue('alertEnd')), 'dd.MM.yyyy HH:mm')}
      </span>
    ),
  },
];

export const replacementColumns: Array<ColumnDef<Replacement>> = [
  {
    header: 'Цвет',
    accessorKey: 'color',
  },
  {
    header: 'Описание',
    accessorKey: 'description',
  },
  {
    header: 'Дата замены',
    accessorKey: 'timeInsert',
    cell: ({ row }) => (
      <span className="whitespace-nowrap">
        {format(new Date(row.getValue('timeInsert')), 'dd.MM.yyyy HH:mm')}
      </span>
    ),
  },
  {
    header: 'Серийный номер',
    accessorKey: 'sn',
  },
];
