import React, { useEffect, useMemo, useState } from 'react';
import {
  Controller,
  type SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { Skeleton } from '@mui/material';
import { Loader2 } from 'lucide-react';

import type { Role, User, UserCreate } from '@/global/userShema';
import { type Manager } from '@/pages/UsersPage/UsersPage';
import { Button } from '@/shared/ui/Button/Button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/shared/ui/Command/Command';
import { Input } from '@/shared/ui/Input/Input';
import { Label } from '@/shared/ui/Label/Label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/shared/ui/Popover/Popover';
import { Switch } from '@/shared/ui/Switch/Switch';

import { type AutocompleteOption } from '../ui/Autocomplete/Autocomplete';

import styles from './UserForm.module.css';

const createRolesWithCheckedField = (
  roles: Role[],
  activeRoles: Role[] | undefined,
): RoleWithCheckedField[] => {
  const transformed = roles.map((role) => ({
    ...role,
    checked: Boolean(
      activeRoles?.find((ar) => ar.authority === role.authority),
    ),
  }));

  return transformed;
};

const returnBaseRoleObject = (roles: RoleWithCheckedField[]): Role[] => {
  return roles
    ?.filter((r) => r.checked)
    .map((r) => {
      const { checked, ...rest } = r;
      return rest;
    });
};

interface RoleWithCheckedField extends Role {
  checked: boolean;
}

interface UserEditProps {
  user: User | null;
  roleList: Role[];
  aplerUserListOptions: AutocompleteOption[];
  currentAplerUserOption?: AutocompleteOption | null;
  currentAplerUserIsLoading?: boolean;
  loading: boolean;
  create?: boolean;
  onSubmitEdit: (data: Omit<User, 'authorities'>, manager: Manager) => void;
  onSubmitCreate: (data: UserCreate) => void;
}

interface Inputs {
  username: string;
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  active: boolean;
  managerGUID?: string;
  roles: RoleWithCheckedField[];
}

export const UserForm: React.FC<UserEditProps> = (props) => {
  const {
    user,
    roleList,
    onSubmitEdit,
    onSubmitCreate,
    currentAplerUserOption,
    aplerUserListOptions,
    currentAplerUserIsLoading,
    create = false,
    loading,
  } = props;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: useMemo(() => {
      return {
        firstName: user?.firstName,
        email: user?.email,
        lastName: user?.lastName,
        active: user?.active,
        roles: createRolesWithCheckedField(roleList, user?.roles),
      };
    }, [roleList]),
  });

  const [modal, setModal] = useState(false);
  const [manager, setManager] = useState('');

  useEffect(() => {
    if (currentAplerUserOption) {
      setManager(currentAplerUserOption.label);
    }
  }, [currentAplerUserOption]);

  const { fields } = useFieldArray({ control, name: 'roles' });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (user && !create) {
      const { authorities, ...restUser } = user;
      const { managerGUID, ...restData } = data;

      const result = {
        ...restUser,
        ...restData,
        roles: returnBaseRoleObject(data.roles),
      };

      onSubmitEdit(result as Omit<User, 'authorities'>, {
        newGUID: managerGUID,
        prevGUID: currentAplerUserOption?.id,
      });
    }

    if (create) {
      const result = {
        ...data,
        roles: returnBaseRoleObject(data.roles),
      };

      onSubmitCreate(result as UserCreate);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.userEdit}>
      {create && (
        <div>
          <Label>Логин</Label>

          <Input
            {...register('username', {
              required: { value: true, message: 'Поле обязательно' },
            })}
            placeholder="Логин"
            error={!!errors.username}
          />
        </div>
      )}

      <div>
        <Label>Имя</Label>

        <Input
          {...register('firstName')}
          placeholder="Имя"
          error={!!errors.firstName}
        />
      </div>

      <div>
        <Label>Фамилия</Label>

        <Input
          {...register('lastName')}
          placeholder="Фамилия"
          error={!!errors.lastName}
        />
      </div>

      {create && (
        <div>
          <Label>Пароль</Label>

          <Input
            {...register('password', {
              required: { value: true, message: 'Поле обязательно' },
              minLength: { value: 6, message: 'Минимальная длина 6 символов' },
            })}
            placeholder="Пароль"
            error={!!errors.password}
          />
        </div>
      )}

      <div>
        <Label>Email</Label>

        <Input
          {...register('email', {
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Невалидный адрес электронной почты',
            },
          })}
          error={!!errors.email}
          placeholder="Email"
        />
      </div>

      <Controller
        control={control}
        name="active"
        render={({ field }) => (
          <Label className="flex items-center gap-2 font-normal">
            <Switch checked={field.value} onCheckedChange={field.onChange} />
            Активен
          </Label>
        )}
      />

      {fields.length && <Label>Роли</Label>}
      {fields.map((obj, index) => (
        <Controller
          key={obj.id}
          control={control}
          name={`roles.${index}.checked`}
          render={({ field }) => (
            <Label className="flex items-center gap-2 font-normal">
              <Switch checked={field.value} onCheckedChange={field.onChange} />

              {obj.description}
            </Label>
          )}
        />
      ))}

      {!create && (
        <Controller
          control={control}
          name="managerGUID"
          render={({ field }) => {
            if (currentAplerUserIsLoading) {
              return (
                <div>
                  <Skeleton width="100%" height={14} />
                  <Skeleton width="100%" height={38} />
                </div>
              );
            }

            return (
              <Popover open={modal} onOpenChange={setModal}>
                <PopoverTrigger asChild>
                  <Button className="w-full" variant="outline" role="combobox">
                    {manager ?? 'Выбрать'}
                  </Button>
                </PopoverTrigger>

                <PopoverContent className="popover-trigger-width">
                  <Command className="w-full left-0 right-0">
                    <CommandInput placeholder="Поиск" />
                    <CommandEmpty>Не найдено совпадений</CommandEmpty>

                    <CommandGroup className="max-h-[200px] overflow-y-auto">
                      {aplerUserListOptions.map(({ id, label }) => (
                        <CommandItem
                          onSelect={() => {
                            field.onChange(id);
                            setManager(label);
                            setModal(false);
                          }}
                          key={id}
                          value={label}
                        >
                          {label}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>
            );
          }}
        />
      )}

      <Button type="submit" disabled={loading}>
        {create ? 'Создать' : 'Сохранить'}

        {loading && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
      </Button>
    </form>
  );
};
