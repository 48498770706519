import {
  Activity,
  Calendar,
  Info,
  Layers,
  Layers3,
  Scroll,
} from 'lucide-react';

import { type WidgetId } from '@/global/dashboard.interface';

interface WidgetIconProps {
  type?: WidgetId;
}

export const WidgetIcon = ({ type }: WidgetIconProps) => {
  switch (type) {
    case 'DEVICE_ONLINE':
    case 'DEVICE_STATE':
      return (
        <Activity className="w-4 h-4 text-muted-foreground stroke-blue-400" />
      );
    case 'PRINTED_JOBS':
      return (
        <Layers3 className="w-4 h-4 text-muted-foreground stroke-blue-400" />
      );
    case 'MONTH_COUNTER':
    case 'MONTH_JOBS':
    case 'WEEKLY_JOBS':
    case 'DAYS_OF_WEAK_COUNT':
    case 'DAYS_OF_MONTH_COUNT':
    case 'WEEKLY_COUNTER':
      return <Info className="w-4 h-4 text-muted-foreground stroke-blue-400" />;
    default:
      return null;
  }
};
