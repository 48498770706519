import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { api } from './queries/api';
import authReducer from './slices/authSlice';
import themeReducer from './slices/themeSlice';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authReducer,
  theme: themeReducer
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [api.reducerPath]
};

const persistedStore = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedStore,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(api.middleware),
  devTools: true
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
