import { type ColumnDef } from '@tanstack/react-table';

import { DataTableColumnHeader } from '@/components/DataTable/DataTableColumnHeader';
import { type IParam } from '@/global/types';
import { Badge } from '@/shared/ui/Badge/Badge';

export const columns: Array<ColumnDef<IParam>> = [
  {
    id: 'Название',
    accessorKey: 'param_name',
    header: 'Название',
    cell: ({ row }) => (
      <Badge className="text-sm" variant="outline">
        {row.getValue('Название')}
      </Badge>
    ),
  },
  {
    id: 'Описание',
    accessorKey: 'description',
    header: 'Описание',
  },
  {
    id: 'Тип',
    accessorKey: 'param_type',
    header: 'Тип',
    cell: ({ row }) => (
      <Badge className="min-w-[70px] justify-center" variant="outline">
        {row.getValue('Тип')}
      </Badge>
    ),
  },
  {
    id: 'Значение',
    accessorKey: 'param_value',
    header: 'Значение',
    cell: ({ row }) =>
      row.original.param_values ? (
        <span>
          [
          {row.original.param_values.map((val, i) => (
            <span key={val}>
              {i ? ', ' : ''}
              {val}
            </span>
          ))}
          ]
        </span>
      ) : (
        <span>{row.original.param_value}</span>
      ),
  },
];
