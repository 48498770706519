import React from 'react';
import { Grid } from '@mui/material';

import Item from './Item/Item';
import type { DeviceListProps } from './DeviceList.props';

export const DeviceList: React.FC<DeviceListProps> = ({
  data,
  className,
  ...props
}) => {
  return (
    <div {...props}>
      <Grid container className="relative">
        <span className="absolute h-full w-[1px] bg-background" />
        <span className="absolute bottom-0 h-[1px] w-full bg-background" />

        {data.map((printer) => (
          <Grid key={printer.id} item xs={12} sm={6} md={4} lg={3} xl={12 / 5}>
            <Item printer={printer} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
