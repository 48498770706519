import React from 'react';
import { Grid } from '@mui/material';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/shared/ui/Select/Select';

import type { FiltersProps } from './Filters.props';
import { Search } from './Search';

import styles from './Filters.module.css';

const Filters = ({
  handleChange,
  filters,
  activeFilters,
  setSearch,
}: FiltersProps) => {
  return (
    <Grid className={styles.filters} container sx={{ gap: '5px' }}>
      <Grid item>
        <Search setSearch={setSearch} />
      </Grid>

      {filters &&
        activeFilters &&
        handleChange &&
        filters.map((filter) => (
          <Grid item key={filter.field}>
            <Select
              onValueChange={(value) => {
                handleChange(filter.field, value === 'all' ? '' : value);
                console.log('SELECT', value);
              }}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder={`${filter?.title} (все)`} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="all">{filter.title} (все)</SelectItem>
                  {filter.options.map(({ value, title }) => (
                    <SelectItem key={title} value={value}>
                      {title}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            {/* <Filter */}
            {/*   filter={filter} */}
            {/*   handleChange={handleChange} */}
            {/*   active={activeFilters[filter.field]} */}
            {/* /> */}
          </Grid>
        ))}
    </Grid>
  );
};

export default React.memo(Filters);
