interface IErrorData {
  error: string
  status: number
}

export interface IError {
  status: number
  data: IErrorData
}

export const parseError = (error: IError) => {
  let message: string = '';

  if (error.status && error.data.error) {
    console.log(error);
    message = `Статус: ${error.status} Сообщение: ${error.data.error}`;
  } else {
    message = `Статус: ${error.status}`;
  }

  return message;
};
