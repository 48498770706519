import React, { useCallback, useMemo, useState } from 'react';
import { Snackbar } from '@mui/material';
import { type FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { Plus, UserCog } from 'lucide-react';

import { Loading } from '@/components';
import { DataTable } from '@/components/DataTable/DataTable';
import { ErrorUi } from '@/components/ErrorUi/ErrorUi';
import { OHiddenWrapper } from '@/components/OHiddenWrapper/OHiddenWrapper';
import { PageTitle } from '@/components/PageTitle/PageTitle';
import { type AutocompleteOption } from '@/components/ui/Autocomplete/Autocomplete';
import { UserForm } from '@/components/UserForm/UserForm';
import type { UnrelatedUser, User, UserCreate } from '@/global/userShema';
import { showResponseStatusCode } from '@/helpers/showResponseStatusCode';
import { withLayout } from '@/layout/Layout';
import { Button } from '@/shared/ui/Button/Button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/shared/ui/Dialog/Dialog';
import {
  useCreateUserMutation,
  useGetAplerUsersQuery,
  useGetUserRolesQuery,
  useGetUsersQuery,
  useLazyGetAplerUserByUserIdQuery,
  useLinkUserWithAplerUserMutation,
  useSaveUserMutation,
} from '@/store/queries/userApi';

import { columns } from './columns';

export function isFetchBaseQueryError(
  error: unknown,
): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

const createAutocompleteOptionsArray = (
  users: UnrelatedUser[],
): AutocompleteOption[] => {
  const options: AutocompleteOption[] = [];

  users?.forEach((user) => {
    options.push({ id: user.emplGUID, label: user.emplName });
  });

  return options;
};

export interface Manager {
  prevGUID?: string;
  newGUID?: string;
}

interface UsersPageProps {}

const UsersPage: React.FC<UsersPageProps> = () => {
  const {
    isLoading: usersLoading,
    data = [],
    isError,
    error,
    refetch,
  } = useGetUsersQuery();

  const [user, setUser] = useState<User | null>(null);
  const [isCreate, setIsCreate] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
  });

  const [modalOpen, setModalOpen] = useState(false);

  const [saveUser, { isLoading: saveUserLoading }] = useSaveUserMutation();
  const [linkUser] = useLinkUserWithAplerUserMutation();
  const [createUser, { isLoading: createUserLoading }] =
    useCreateUserMutation();
  const [
    getApplerUserByUserId,
    { data: aplerUser, isFetching: aplerUserIsFetching },
  ] = useLazyGetAplerUserByUserIdQuery();

  const { data: roleList = [] } = useGetUserRolesQuery();
  const { data: unrelatedUserList = [], refetch: refetchUnrelatedUserList } =
    useGetAplerUsersQuery();

  const aplerUserOption = useMemo(() => {
    if (!aplerUser) {
      return null;
    }

    const option: AutocompleteOption = {
      id: aplerUser.emplGUID,
      label: aplerUser.emplName,
    };

    return option;
  }, [aplerUser]);

  const aplerUserListOptions = useMemo(() => {
    const options = createAutocompleteOptionsArray(unrelatedUserList);

    if (aplerUserOption) {
      options.push(aplerUserOption);
    }

    return options;
  }, [unrelatedUserList, aplerUserOption]);

  const handleEditUser = (user: User) => {
    getApplerUserByUserId(user.id);
    setIsCreate(false);
    setUser(user);
    setModalOpen(true);
  };

  const handleCreateUser = useCallback((data: UserCreate) => {
    createUser(data)
      .unwrap()
      .then(() => {
        setSnackbar({ message: 'Пользователь создан', open: true });
        setModalOpen(false);
      })
      .catch((error) => {
        if (isFetchBaseQueryError(error)) {
          setSnackbar({
            open: true,
            message: `Произошла ошибка при создании. Статус ${error.status}`,
          });
        }
      });
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setUser(null);
  }, []);

  const tableColumns = useMemo(
    () =>
      columns.concat({
        id: 'edit',
        cell: ({ row }) => (
          <Button
            variant="outline"
            size="icon"
            className="w-[35px] h-[35px] p-1.5"
            onClick={() => {
              handleEditUser(row.original);
            }}
          >
            <UserCog className="w-full" />
          </Button>
        ),
      }),
    [],
  );

  const handleSubmit = useCallback(
    (data: Omit<User, 'authorities'>, manager: Manager) => {
      saveUser(data)
        .unwrap()
        .then(() => {
          setSnackbar({ message: 'Пользователь сохранен', open: true });
          setModalOpen(false);
        })
        .catch((error) => {
          if (isFetchBaseQueryError(error)) {
            setSnackbar({
              open: true,
              message: `Произошла ошибка при сохрании. Статус ${error.status}`,
            });
          }
        });

      if (manager.newGUID && manager.newGUID !== manager.prevGUID) {
        linkUser({ userId: data.id, managerGUID: manager.newGUID })
          .unwrap()
          .then(() => {
            refetchUnrelatedUserList();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    [],
  );

  const handleCloseSnackbar = useCallback(() => {
    setSnackbar({ ...snackbar, open: false });
  }, []);

  const toggleCreateUser = useCallback(() => {
    setUser(null);
    setIsCreate(true);
    setModalOpen(true);
  }, []);

  return (
    <OHiddenWrapper className="gap-y-4 ">
      <div className="flex gap-6">
        <PageTitle title="Пользователи" />

        <Button size="sm" className="ml-auto flex" onClick={toggleCreateUser}>
          Добавить
          <Plus className="ml-2 h-4 w-4" />
        </Button>
      </div>

      <DataTable showToolbar={false} columns={tableColumns} data={data} />

      <Loading isLoading={usersLoading} title="Пользователи" />
      <ErrorUi
        isError={isError}
        title={`${showResponseStatusCode(error)}`}
        onRefetch={refetch}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
      />

      <Dialog open={modalOpen} onOpenChange={handleCloseModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {isCreate ? 'Создание' : 'Редактирование'}
            </DialogTitle>
          </DialogHeader>

          <UserForm
            create={isCreate}
            loading={saveUserLoading || createUserLoading}
            onSubmitEdit={handleSubmit}
            onSubmitCreate={handleCreateUser}
            user={user}
            roleList={roleList}
            aplerUserListOptions={aplerUserListOptions}
            currentAplerUserIsLoading={aplerUserIsFetching}
            currentAplerUserOption={aplerUserOption}
          />
        </DialogContent>
      </Dialog>
    </OHiddenWrapper>
  );
};

export default withLayout(UsersPage);
