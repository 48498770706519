import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { IUser } from '@/global/types';

interface IState {
  user: IUser | null;
  authenticated: boolean;
}

const initialState: IState = {
  user: null,
  authenticated: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authSuccess: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
      state.authenticated = true;
    },
    authFailed: (state) => {
      state.user = null;
      state.authenticated = false;
    },
  },
});

export const { authSuccess, authFailed } = authSlice.actions;

export default authSlice.reducer;
