import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { ThemeTypes } from '@/global/types';

const getTheme = () => {
  const localTheme = String(localStorage.getItem('theme'));
  if (['light', 'dark'].includes(localTheme)) return localTheme as ThemeTypes;
  const theme = window.matchMedia('(prefers-color-scheme: light)');
  if (theme.matches) return 'light';
  return 'dark';
};

const initialState: ThemeTypes = getTheme();

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<ThemeTypes>) => action.payload,
  },
});

export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;
