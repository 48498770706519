import type { IAlert, IChart, IPrinter, Replacement } from '@/global/types';

import { api } from './api';

interface ChartProps {
  id: string | number;
  dateStart: string;
  dateEnd: string;
}

const printerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDevices: builder.query<IPrinter[], void>({
      query: () => '/v2/printers/get-with-sup',
    }),
    getChart: builder.query<IChart[], ChartProps>({
      query: ({ id, dateEnd, dateStart }) =>
        `/v2/counter/dev-count?idDev=${id}&dateStart=${dateStart}&dateEnd=${dateEnd}`,
    }),
    getReplacements: builder.query<Replacement[], string>({
      query: (id) => `/v2/change_log/get/${id}`,
    }),
    getAlerts: builder.query<IAlert[], ChartProps>({
      query: ({ id, dateEnd, dateStart }) =>
        `/v2/alerts/device-alerts?idDev=${id}&dateStart=${dateStart}&dateEnd=${dateEnd}`,
    }),
  }),
});

export const {
  useGetDevicesQuery,
  useGetReplacementsQuery,
  useLazyGetAlertsQuery,
  useLazyGetChartQuery,
} = printerApi;
