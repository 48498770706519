import { useEffect, useState } from 'react';
import { Loader2 } from 'lucide-react';

import {
  type ChartData,
  createChartData,
} from '@/components/Dashboard/PieChartWidget/PieChartWidget';
import { LineChart } from '@/components/LineChart/LineChart';
import { useGetWidgetDetailsQuery } from '@/store/queries/dashboardApi';

interface LineChartWidgetProps {
  dataUrl: string;
}

export const LineChartWidget = ({ dataUrl }: LineChartWidgetProps) => {
  const { data, isLoading, error } = useGetWidgetDetailsQuery(dataUrl);

  const [chartData, setChartData] = useState<ChartData[]>([]);

  console.log(data);

  useEffect(() => {
    if (data?.widgetItems) {
      setChartData(createChartData(data.widgetItems));
    }
  }, [data]);

  if (isLoading) {
    return <Loader2 className="animate-spin m-auto text-muted-foreground" />;
  }

  if (error || !data) {
    return <p>Произошла ошибка</p>;
  }

  return <LineChart data={chartData} />;
};
