import { type IPrinter } from '@/global/types';

type PlacementTypes =
| 'bottom-end'
| 'bottom-start'
| 'bottom'
| 'left-end'
| 'left-start'
| 'left'
| 'right-end'
| 'right-start'
| 'right'
| 'top-end'
| 'top-start'
| 'top';

interface IBlock {
  tooltip: string
  placement: PlacementTypes
  cl: string
  field: keyof IPrinter
}

type IDetails = Record<string, IBlock[]>;

export const details: IDetails = {
  block2: [
    {
      tooltip: 'Серийный номер',
      cl: 'sn',
      placement: 'right',
      field: 'sn'
    },
    {
      tooltip: 'Счетчик',
      cl: 'counter',
      placement: 'left',
      field: 'counter'
    }
  ],
  block3: [
    {
      tooltip: 'MAC адрес',
      cl: 'mac',
      placement: 'right',
      field: 'mac'
    },
    {
      tooltip: 'IP адрес',
      cl: 'ip',
      placement: 'left',
      field: 'ip'
    }
  ]
};
