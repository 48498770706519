import { type Order, type Orders } from '../global/types';

export const formatOrdersMap = (orders: Orders): Order[] => {
  const result: Order[] = [];

  for (const key in orders) {
    for (const elem of orders[key]) {
      result.push(elem);
    }
  }

  return result;
};
