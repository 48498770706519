import React, {
  type ChangeEvent,
  type Dispatch,
  type KeyboardEvent,
  type SetStateAction,
  useState,
} from 'react';
import { Loader2, Plus, Trash } from 'lucide-react';

import type { IParam } from '@/global/types';
import { Button } from '@/shared/ui/Button/Button';
import { Input } from '@/shared/ui/Input/Input';
import { Label } from '@/shared/ui/Label/Label';

import styles from './QueueForm.module.css';
import { Controller, useForm } from 'react-hook-form';
import { Switch } from '@/shared/ui/Switch/Switch';
import { type IQueueForm, useCreateQueueMutation } from '@/store/queries/queueApi';

interface QueueFormProps {
  param: Partial<IParam>;
  setParam: Dispatch<SetStateAction<Partial<IParam>>>;
  submit: () => void;
  loading: boolean;
}

interface IField {
  title: string;
  field: keyof IParam;
}

const fields: IField[] = [
  {
    title: 'Название',
    field: 'param_name',
  },
  {
    title: 'Описание',
    field: 'description',
  },
];

export const QueueForm: React.FC = () => {
  const {register,
    control,
    handleSubmit} = useForm<IQueueForm>();
  const [createQueue] = useCreateQueueMutation();
  const onSubmit = (data:IQueueForm) => {
    createQueue(data);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.editParam}>
        <div>
          <Label>
            Имя очереди
          </Label>
          <Input {...register('queueName')}/>
        </div>
        <div>
          <Label>
            Доступность
          </Label>
          <Controller
            control={control}
            name="direct"
            render={({ field }) => (
              <Switch checked={field.value} onCheckedChange={field.onChange} />
            )}
          />
        </div>
        <div>
          <Label>
            Принтер
          </Label>
          <Input {...register('printer')}/>
        </div>
        <div>
          <Label>
            Активность
          </Label>
          <Controller
            control={control}
            name="active"
            render={({ field }) => (
              <Switch checked={field.value} onCheckedChange={field.onChange} />
            )}
          />
        </div>
        <div>
          <Label>
            Тип очереди
          </Label>
          <Input {...register('type')}/>
        </div>
        <Button type={'submit'}>
          Сохранить
        </Button>
      </div>
    </form>
  );
};
