import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material';

import type { IColumn, IPrinter } from '@/global/types';
import { Badge } from '@/shared/ui/Badge/Badge';

const Link = styled(RouterLink)`
  color: var(--white);
  &:hover {
    color: var(--blue);
  }
`;

export const columns: Array<IColumn<IPrinter>> = [
  {
    title: 'Аппарат',
    field: 'name',
    Cell: (row: IPrinter) => <Link to={`/device/${row.id}`}>{row.name}</Link>,
    style: { minWidth: 200 },
  },
  {
    title: 'Статус',
    field: 'state',
    Cell: (row: IPrinter) =>
      row.state ? (
        <Badge variant="outline" className="text-green-600">
          в сети
        </Badge>
      ) : (
        <Badge variant="outline" className="text-red-600">
          выкл.
        </Badge>
      ),
  },
  {
    title: 'IP',
    field: 'ip',
    Cell: ({ ip }: IPrinter) => <Badge variant="outline">{ip}</Badge>,
  },
  {
    title: 'Серийный номер',
    field: 'sn',
  },
  { title: 'MAC', field: 'mac', style: { textTransform: 'uppercase' } },
  {
    title: 'Текущий счетчик',
    field: 'counter',
    style: { minWidth: 140 },
  },
];

export interface IOption {
  title: string;
  value: string;
}

export type FilterFieldTypes =
  | 'vendor'
  | 'printTech'
  | 'printFormat'
  | 'state'
  | 'colorPrint';

export interface IFilter<Fields extends string = string> {
  field: Fields;
  title: string;
  options: IOption[];
}

export const printersFilter: Array<IFilter<FilterFieldTypes>> = [
  {
    field: 'vendor',
    title: 'Вендор',
    options: [
      { title: 'Hewlett-Packard', value: 'Hewlett-Packard' },
      { title: 'Xerox', value: 'Xerox' },
      { title: 'Seiko Epson Corporation', value: 'Seiko Epson Corporation' },
      { title: 'OKI', value: 'OKI' },
      { title: 'Samsung', value: 'Samsung' },
    ],
  },
  {
    field: 'printTech',
    title: 'Технология',
    options: [
      { title: 'Лазерная', value: '4' },
      { title: 'Светодиодная', value: '3' },
      { title: 'Струйная', value: '12' },
    ],
  },

  {
    field: 'printFormat',
    title: 'Формат',
    options: [
      { title: 'A4', value: 'A4' },
      { title: 'A0', value: 'A0' },
      { title: 'SRA3', value: 'SRA3' },
    ],
  },
  {
    field: 'state',
    title: 'В сети',
    options: [
      { title: 'Включен', value: '1' },
      { title: 'Выключен', value: '0' },
    ],
  },
  {
    field: 'colorPrint',
    title: 'Цветность',
    options: [
      { title: 'Монохромный', value: '1' },
      { title: 'Цветной CMYK', value: '4' },
      { title: 'Цветной CMYK + 1', value: '5' },
    ],
  },
];
