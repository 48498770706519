import { LayoutGrid, type LucideIcon, TableProperties } from 'lucide-react';

interface IView {
  title: string;
  variant: 0 | 1;
  icon: LucideIcon;
}

export const views: IView[] = [
  {
    title: 'Таблицей',
    variant: 0,
    icon: TableProperties,
  },
  {
    title: 'Карточками',
    variant: 1,
    icon: LayoutGrid,
  },
];
