import { HiMoon, HiSun } from 'react-icons/hi';

import type { Icon, ThemeTypes } from '@/global/types';

type ThemeClassTypes = 'moon' | 'sun';

interface ITheme {
  theme: ThemeTypes;
  cl: ThemeClassTypes;
  icon: Icon;
}

export const themes: ITheme[] = [
  {
    theme: 'light',
    cl: 'sun',
    icon: HiSun,
  },
  {
    theme: 'dark',
    cl: 'moon',
    icon: HiMoon,
  },
];
