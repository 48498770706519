import React, { useEffect, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { Loader2 } from 'lucide-react';

import { type IError, parseError } from '@/helpers/parseError';
import { Button } from '@/shared/ui/Button/Button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/shared/ui/Card/Card';
import { Input } from '@/shared/ui/Input/Input';
import { useLazyAuthorizationQuery } from '@/store/queries/authApi';

interface Inputs {
  username: string;
  password: string;
}

export const Auth = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const [authorization, { error, isLoading }] = useLazyAuthorizationQuery();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    authorization(data);
  };

  useEffect(() => {
    if (error) {
      const message = parseError(error as IError);
      setErrorMessage(message);
    } else {
      setErrorMessage('');
    }
  }, [error]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="h-[100vh] w-full flex items-center justify-center p-4"
    >
      <Card className="min-w-[300px] max-[350px]:w-[100%]">
        <CardHeader>
          <CardTitle>Вход</CardTitle>
        </CardHeader>

        <CardContent className="flex flex-col gap-3">
          <div>
            <Input
              error={!!errors.username}
              autoFocus
              {...register('username', { required: true })}
              placeholder="Логин"
            />

            {errors.username && (
              <span className="text-sm text-destructive">Заполните</span>
            )}
          </div>

          <div>
            <Input
              error={!!errors.password}
              type="password"
              {...register('password', { required: true })}
              placeholder="Пароль"
            />

            {errors.password && (
              <span className="text-sm text-destructive">Заполните</span>
            )}
          </div>

          {errorMessage && (
            <span className="text-sm text-destructive">{errorMessage}</span>
          )}
        </CardContent>

        <CardFooter>
          <Button className="w-full" disabled={isLoading} type="submit">
            Войти
            {isLoading && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
          </Button>
        </CardFooter>
      </Card>
    </form>
  );
};
