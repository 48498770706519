import { type ColumnDef } from '@tanstack/react-table';

import { type User } from '@/global/userShema';
import { Badge } from '@/shared/ui/Badge/Badge';

export const columns: Array<ColumnDef<User>> = [
  {
    accessorKey: 'id',
    header: 'ID',
  },
  {
    accessorKey: 'username',
    header: 'Логин',
  },
  {
    accessorKey: 'firstName',
    header: 'Имя',
  },
  {
    accessorKey: 'lastName',
    header: 'Фамилия',
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'active',
    header: 'Активен',
    cell: ({ row }) => (
      <Badge
        className={
          row.getValue('active') ? 'text-green-600' : 'text-destructive'
        }
        variant="outline"
      >
        {String(row.getValue('active'))}
      </Badge>
    ),
  },
];
