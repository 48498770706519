import formatter from 'format-number';
import {
  CartesianGrid,
  Line,
  LineChart as AreaChartRechart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { CustomTooltip } from '@/components/Dashboard/CustomTooltip/CustomTooltip';

export interface LineChartData {
  name: string;
  value: string | number;
}

interface LineChartProps {
  data: LineChartData[];
  height?: number;
}

export const LineChart = ({ data, height = 300 }: LineChartProps) => {
  return (
    <ResponsiveContainer width="100%" height={height}>
      <AreaChartRechart data={data}>
        <YAxis
          type="number"
          domain={['dataMin', 'dataMax']}
          stroke="#888888"
          fontSize={12}
          radius={10}
          tickLine={false}
          axisLine={false}
          tickFormatter={(value) => formatter({ integerSeparator: ' ' })(value)}
        />

        <XAxis
          dy={5}
          dataKey="name"
          fontSize={12}
          tickLine={false}
          axisLine={false}
        />

        <CartesianGrid strokeDasharray="3 3" className="stroke-border" />

        <Line type="monotone" dataKey="value" stroke="currentColor" />

        <Tooltip
          cursor={{ radius: 4, opacity: '45%' }}
          content={CustomTooltip as any}
        />
      </AreaChartRechart>
    </ResponsiveContainer>
  );
};
