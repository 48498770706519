import { useEffect, useState } from 'react';
import { Loader2 } from 'lucide-react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

import { CustomTooltip } from '@/components/Dashboard/CustomTooltip/CustomTooltip';
import {
  type WidgetColor,
  type WidgetItem,
} from '@/global/dashboard.interface';
import { useGetWidgetDetailsQuery } from '@/store/queries/dashboardApi';

interface RingDatagramProps {
  dataUrl: string;
}

export interface ChartData {
  name: string;
  color: WidgetColor;
  value: string | number;
}

const chartColorMap: Record<WidgetColor, { fill: string; bg: string }> = {
  rad: { fill: 'fill-red-400', bg: 'bg-red-400' },
  red: { fill: 'fill-red-400', bg: 'bg-red-400' },
  black: { fill: 'fill-black', bg: 'bg-black' },
  blue: { fill: 'fill-blue-600', bg: 'bg-blue-600' },
  gray: { fill: 'fill-gray-600', bg: 'bg-gray-600' },
  green: { fill: 'fill-green-400', bg: 'bg-green-400' },
  orange: { fill: 'fill-orange-600', bg: 'bg-orange-600' },
  yellow: { fill: 'fill-yellow-600', bg: 'bg-yellow-600' },
};

export const createChartData = (
  data: Record<string, WidgetItem>,
): ChartData[] => {
  const result: ChartData[] = [];

  Object.values(data).forEach(({ itemValue, itemColor, legendName }) => {
    result.push({ value: itemValue, name: legendName, color: itemColor });
  });

  return result;
};

const CustomizedLegend = (props: {
  payload: Array<{
    value: string;
    payload: { value: string; color: WidgetColor };
  }>;
}) => {
  const { payload } = props;

  return (
    <ul className="flex justify-center gap-4">
      {payload.map((entry, index) => (
        <li className="flex items-center gap-1" key={`item-${index}`}>
          <span
            className={`w-3 h-3 rounded-sm ${
              chartColorMap[entry.payload.color].bg
            }`}
          />

          <p className="text-sm dark:text-muted-foreground">{entry.value}</p>
        </li>
      ))}
    </ul>
  );
};

export const PieChartWidget = ({ dataUrl }: RingDatagramProps) => {
  const { data, isLoading, error } = useGetWidgetDetailsQuery(dataUrl);

  const [chartData, setChartData] = useState<ChartData[]>([]);

  useEffect(() => {
    if (data?.widgetItems) {
      setChartData(createChartData(data.widgetItems));
    }
  }, [data]);

  if (isLoading) {
    return <Loader2 className="animate-spin m-auto text-muted-foreground" />;
  }

  if (error || !data) {
    return <p>Произошла ошибка</p>;
  }

  return (
    <ResponsiveContainer width="100%" height={250}>
      <PieChart>
        <Pie
          data={chartData}
          cx="50%"
          cy="50%"
          innerRadius={35}
          outerRadius={80}
          label
          dataKey="value"
        >
          {chartData.map(({ value, color, name }, index) => (
            <Cell
              key={`cell-${index}`}
              className={`${chartColorMap[color].fill} stroke-border`}
            />
          ))}
        </Pie>

        <Tooltip content={CustomTooltip as any} />

        <Legend content={CustomizedLegend as any} height={36} />
      </PieChart>
    </ResponsiveContainer>
  );
};
