import { type Table } from '@tanstack/react-table';
import { X } from 'lucide-react';

import { DataTableViewOptions } from '@/components/DataTable/DataTableViewOptions';
import { Button } from '@/shared/ui/Button/Button';
import { Input } from '@/shared/ui/Input/Input';

interface DataTableToolbarProps<TData> {
  searchFieldId?: string;
  searchPlaceholder?: string;
  table: Table<TData>;
}

export const DataTableToolbar = <TData,>({
  searchFieldId,
  searchPlaceholder = 'Поиск',
  table,
}: DataTableToolbarProps<TData>) => {
  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <div className="flex items-center items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        {searchFieldId && (
          <Input
            placeholder={searchPlaceholder}
            value={
              (table.getColumn(searchFieldId)?.getFilterValue() as string) ?? ''
            }
            onChange={(event) =>
              table.getColumn(searchFieldId)?.setFilterValue(event.target.value)
            }
            className="h-8 w-[150px] lg:w-[250px]"
          />
        )}

        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => {
              table.resetColumnFilters();
            }}
            className="h-8 px-2 lg:px-3"
          >
            Сбросить
            <X className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>

      <DataTableViewOptions table={table} />
    </div>
  );
};
