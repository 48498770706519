import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Snackbar } from '@mui/material';

import { EditParam, Loading } from '@/components';
import { DataTable } from '@/components/DataTable/DataTable';
import { ErrorUi } from '@/components/ErrorUi/ErrorUi';
import { OHiddenWrapper } from '@/components/OHiddenWrapper/OHiddenWrapper';
import { PageTitle } from '@/components/PageTitle/PageTitle';
import type { IParam, IParamOrig } from '@/global/types';
import { type IError, parseError } from '@/helpers/parseError';
import { showResponseStatusCode } from '@/helpers/showResponseStatusCode';
import { withLayout } from '@/layout/Layout';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/shared/ui/Dialog/Dialog';
import {
  useGetParamsQuery,
  useUpdateParamMutation,
} from '@/store/queries/paramApi';

import { columns } from './columns';
import { useGetQueuesQuery } from '@/store/queries/queueApi';
import { Button } from '@/shared/ui/Button/Button';
import { Plus } from 'lucide-react';
import { UserForm } from '@/components/UserForm/UserForm';
import { QueueForm } from '@/components/QueueForm/QueueForm';

const initialState: Partial<IParam> = {
  description: '',
  param_name: '',
  param_value: '',
  param_values: [],
};

const QueuePage = () => {
  const {
    data: qeues = [],
    isLoading,
    isError,
    error,
    refetch,
  } = useGetQueuesQuery();

  const [param, setParam] = useState<Partial<IParam>>(initialState);
  const [drawer, setDrawer] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [snackbar, setSnakbar] = useState<string>('');

  const [updateParam, { isLoading: isUpdating, isSuccess }] =
    useUpdateParamMutation();

  const closeDrawer = () => {
    setDrawer(false);
    setParam(initialState);
  };

  const editParam = useCallback((param: IParam) => {
    setDrawer(true);
    setParam(param);
  }, []);

  const submitEdit = () => {
    const copyParam = { ...param };

    if (copyParam.param_values) {
      copyParam.param_value = JSON.stringify(copyParam.param_values);
      delete copyParam.param_values;
    }

    updateParam(copyParam as IParamOrig);
  };

  useEffect(() => {
    if (!isUpdating && isSuccess) {
      setDrawer(false);
      setParam(initialState);
      setSnakbar('Сохранено успешно');
    }

    if (error) {
      setSnakbar(parseError(error as IError));
    }
  }, [isUpdating]);

  const handleCloseSnackbar = () => {
    setSnakbar('');
  };

  const toggleCreateQueue = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <OHiddenWrapper className="gap-y-4">

      <div className="flex gap-6">
        <PageTitle title="Очереди" />

        <Button size="sm" className="ml-auto flex" onClick={toggleCreateQueue}>
          Добавить
          <Plus className="ml-2 h-4 w-4" />
        </Button>
      </div>

      <ErrorUi
        onRefetch={refetch}
        isError={isError}
        title={`${showResponseStatusCode(error)}`}
      />

      <Loading isLoading={isLoading} title="Параметры" />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!snackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbar}
      />

      <DataTable
        searchPlaceholder="Поиск по названию"
        searchFieldId="Название"
        columns={columns}
        data={qeues}
      />
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Создание очереди
            </DialogTitle>
          </DialogHeader>
          <QueueForm
          />

        </DialogContent>
      </Dialog>
    </OHiddenWrapper>
  );
};

export default withLayout(QueuePage);
