import type { IUser } from '@/global/types';
import { authFailed, authSuccess } from '@/store/slices/authSlice';

import { api } from './api';

interface IAuthForm {
  username: string;
  password: string;
}

function authenticateUser(username: string, password: string) {
  const token = username + ':' + password;
  const hash = btoa(token);

  return 'Basic ' + hash;
}

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    authorization: builder.query<IUser, IAuthForm>({
      query: ({ username, password }) => ({
        url: '/user/get-user',
        headers: {
          Authorization: authenticateUser(username, password),
        },
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(authSuccess(data));
        } catch (e) {
          dispatch(authFailed());
        }
      },
    }),
  }),
});

export const { useLazyAuthorizationQuery } = authApi;
