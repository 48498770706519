import React, {
  type ChangeEvent,
  type Dispatch,
  type KeyboardEvent,
  type SetStateAction,
  useState,
} from 'react';
import { Loader2, Plus, Trash } from 'lucide-react';

import type { IParam } from '@/global/types';
import { Button } from '@/shared/ui/Button/Button';
import { Input } from '@/shared/ui/Input/Input';
import { Label } from '@/shared/ui/Label/Label';

import styles from './EditParam.module.css';

interface EditParamProps {
  param: Partial<IParam>;
  setParam: Dispatch<SetStateAction<Partial<IParam>>>;
  submit: () => void;
  loading: boolean;
}

interface IField {
  title: string;
  field: keyof IParam;
}

const fields: IField[] = [
  {
    title: 'Название',
    field: 'param_name',
  },
  {
    title: 'Описание',
    field: 'description',
  },
];

export const EditParam: React.FC<EditParamProps> = ({
  param,
  setParam,
  submit,
  loading,
}) => {
  const [newValue, setNewValue] = useState<string>('');
  const [valueExists, setValuesExists] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'new_param') {
      setValuesExists(false);
      setNewValue(e.target.value);
    } else {
      setParam({ ...param, [e.target.name]: e.target.value });
    }
  };

  const handleRemoveValue = (val: string) => {
    setParam({
      ...param,
      param_values: param.param_values?.filter((v) => v !== val),
    });
  };

  const handleAddValue = () => {
    if (param.param_values?.includes(newValue)) {
      setValuesExists(true);
    } else {
      // @ts-expect-error no error
      setParam({ ...param, param_values: [...param.param_values, newValue] });
      setValuesExists(false);
      setNewValue('');
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleAddValue();
    }
  };

  return (
    <div className={styles.editParam}>
      {fields.map(({ field, title }) => (
        <div className={styles.formGroup} key={field}>
          <Label>{title}</Label>
          <Input
            onChange={handleChange}
            name={field}
            value={param[field] as keyof IParam}
          />

          {/* <Input */}
          {/*   className={styles.input} */}
          {/*   onChange={handleChange} */}
          {/*   name={field} */}
          {/*   value={param[field] as keyof IParam} */}
          {/* /> */}
        </div>
      ))}

      <Label>Параметры</Label>

      {param.param_value !== null ? (
        <Input
          onChange={handleChange}
          name="param_value"
          value={param.param_value}
        />
      ) : (
        ''
      )}

      {param.param_values ? (
        <>
          <div className={styles.addValue}>
            <Input
              className="mr-2"
              onKeyDown={handleKeyDown}
              name="new_param"
              onChange={handleChange}
              value={newValue}
            />

            <Button
              className="w-[40px]"
              disabled={!newValue}
              onClick={handleAddValue}
              variant="outline"
              size="icon"
            >
              <Plus />
            </Button>
          </div>

          {valueExists && (
            <span className="text-red-600 text-sm">
              Значение уже существует
            </span>
          )}

          <div className={styles.values}>
            {param.param_values.map((val) => (
              <span
                onClick={() => {
                  handleRemoveValue(val);
                }}
                className="flex items-center h-[35px] rounded-lg border"
                key={val}
              >
                <span className={styles.valueContent}>{val}</span>

                <Trash role="button" className={styles.remove} />
              </span>
            ))}
          </div>
        </>
      ) : (
        ''
      )}

      <Button onClick={submit} disabled={loading}>
        Сохранить
        {loading && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
      </Button>
    </div>
  );
};
