import { type Order, type Orders } from '@/global/types';
import { formatOrdersMap } from '@/helpers/formatOrdersMap';

import { api } from './api';

const orderApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUnpaidOrders: builder.query<Order[], void>({
      query: () => '/v2/order/unpaid-orders',
      transformResponse: (baseQueryReturnValue: Orders): Order[] => {
        return formatOrdersMap(baseQueryReturnValue);
      },
    }),
  }),
});

export const { useGetUnpaidOrdersQuery } = orderApi;
