import React, { type ComponentProps } from 'react';

interface RenderProps extends ComponentProps<'div'> {
  render: boolean
}

export const Render: React.FC<RenderProps> = ({ render, children, ...props }) => {
  return (
    <div style={{ display: render ? 'block' : 'none' }} {...props}>
      {children}
    </div>
  );
};
