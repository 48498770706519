import React, { memo, useLayoutEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import cn from 'classnames';
import {
  AreaChart,
  Bolt,
  File,
  ListEnd,
  Menu,
  NotepadText,
  Printer,
  Users
} from 'lucide-react';

import { SidebarItem } from '@/components/Sidebar/SidebarItem';
import { ToggleTheme } from '@/components/ToggleTheme/ToggleTheme';
import { useTypedSelector } from '@/store/hooks/hooks';

import styles from './Sidebar.module.css';

const list = [
  { title: 'Дашборд', path: '/dashboard', icon: AreaChart },
  { title: 'Принтеры', path: '/printers', icon: Printer },
  { title: 'Работы', path: '/print-jobs', icon: File },
  { title: 'Заказы', path: '/orders', icon: NotepadText },
  { title: 'Пользователи', path: '/users', icon: Users },
  { title: 'Параметры', path: '/params', icon: Bolt },
  { title: 'Очередь', path: '/queues', icon: ListEnd },

];

export const Sidebar = memo(() => {
  const [collapsed, setCollapsed] = useState(false);
  const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });

  const theme = useTypedSelector((state) => state.theme);

  useLayoutEffect(() => {
    if (!isDesktop) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isDesktop]);

  const toggleSidebar = () => {
    setCollapsed((prev) => !prev);
  };

  return (
    <>
      {!isDesktop && (
        <span
          onClick={toggleSidebar}
          className={cn(styles.background, {
            [styles.active]: !collapsed,
          })}
        ></span>
      )}

      <div
        className={cn(
          styles.sidebar,
          'bg-background border-r flex flex-col w-[270px] h-[100vh] z-20',
          'max-[1200px]:fixed -left-[270px]',
          isDesktop && collapsed && 'w-[50px]',
          !isDesktop && !collapsed && 'left-0',
        )}
      >
        <div className="relative flex h-[50px] w-full">
          {!isDesktop && (
            <Menu
              onClick={toggleSidebar}
              className={cn(
                'absolute right-2 top-3 cursor-pointer text-black dark:text-white',
                isDesktop && collapsed && 'left-1/2 -translate-x-1/2',
                !isDesktop && collapsed && 'left-[270px] ml-4',
              )}
            />
          )}
        </div>

        <h3 className="mx-6 text-2xl font-semibold">Меню</h3>

        <div className="mx-2 divide-y">
          <ul className={cn('flex flex-col gap-y-1 py-4 pt-2')}>
            {list.map(({ icon: Icon, title, path }) => (
              <SidebarItem
                key={path}
                collapsed={collapsed}
                path={path}
                title={title}
                icon={Icon}
              />
            ))}
          </ul>

          <div className="flex items-center gap-2 px-2 py-4">
            <label
              role="switch"
              className="flex cursor-pointer text-sm w-full items-center gap-3"
            >
              <ToggleTheme />

              {theme === 'dark' ? 'Темная тема' : 'Светлая тема'}
            </label>
          </div>
        </div>
      </div>
    </>
  );
});
