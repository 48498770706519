import { Loader2 } from 'lucide-react';

import { useGetWidgetDetailsQuery } from '@/store/queries/dashboardApi';

interface SimpleDataProps {
  dataUrl: string;
}

export const SimpleData = ({ dataUrl }: SimpleDataProps) => {
  const { data, isLoading, error } = useGetWidgetDetailsQuery(dataUrl);

  if (isLoading) {
    return <Loader2 className="animate-spin m-auto text-muted-foreground" />;
  }

  if (error || !data) {
    return <p>Произошла ошибка</p>;
  }

  return (
    <>
      {data?.widgetItems &&
        Object.values(data.widgetItems).map(({ legendName, itemValue }) => (
          <div
            className="flex justify-between text-sm gap-2 py-1.5 first:pt-0 last-pb-0 items-center group"
            key={itemValue}
          >
            <p className="text-muted-foreground delay-100 group-hover:text-foreground">
              {legendName}
            </p>

            <p>{itemValue}</p>
          </div>
        ))}
    </>
  );
};
