import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Snackbar } from '@mui/material';
import { FileCog } from 'lucide-react';

import { EditParam, Loading } from '@/components';
import { DataTable } from '@/components/DataTable/DataTable';
import { ErrorUi } from '@/components/ErrorUi/ErrorUi';
import { OHiddenWrapper } from '@/components/OHiddenWrapper/OHiddenWrapper';
import { PageTitle } from '@/components/PageTitle/PageTitle';
import type { IParam, IParamOrig } from '@/global/types';
import { type IError, parseError } from '@/helpers/parseError';
import { showResponseStatusCode } from '@/helpers/showResponseStatusCode';
import { withLayout } from '@/layout/Layout';
import { Button } from '@/shared/ui/Button/Button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/shared/ui/Dialog/Dialog';
import {
  useGetParamsQuery,
  useUpdateParamMutation,
} from '@/store/queries/paramApi';

import { columns } from './columns';

const initialState: Partial<IParam> = {
  description: '',
  param_name: '',
  param_value: '',
  param_values: [],
};

const ParamsPage = () => {
  const {
    data: params = [],
    isLoading,
    isError,
    error,
    refetch,
  } = useGetParamsQuery();

  const [param, setParam] = useState<Partial<IParam>>(initialState);
  const [drawer, setDrawer] = useState<boolean>(false);
  const [snackbar, setSnakbar] = useState<string>('');

  const [updateParam, { isLoading: isUpdating, isSuccess }] =
    useUpdateParamMutation();

  const closeDrawer = () => {
    setDrawer(false);
    setParam(initialState);
  };

  const editParam = useCallback((param: IParam) => {
    setDrawer(true);
    setParam(param);
  }, []);

  const submitEdit = () => {
    const copyParam = { ...param };

    if (copyParam.param_values) {
      copyParam.param_value = JSON.stringify(copyParam.param_values);
      delete copyParam.param_values;
    }

    updateParam(copyParam as IParamOrig);
  };

  useEffect(() => {
    if (!isUpdating && isSuccess) {
      setDrawer(false);
      setParam(initialState);
      setSnakbar('Сохранено успешно');
    }

    if (error) {
      setSnakbar(parseError(error as IError));
    }
  }, [isUpdating]);

  const handleCloseSnackbar = () => {
    setSnakbar('');
  };

  const dataColumns = useMemo(
    () =>
      columns.concat({
        id: 'edit',
        cell: ({ row }) => (
          <Button
            size="icon"
            className="w-[35px] h-[35px] p-1.5"
            variant="outline"
            onClick={() => {
              editParam(row.original);
            }}
          >
            <FileCog className="w-full" />
          </Button>
        ),
      }),
    [editParam],
  );

  return (
    <OHiddenWrapper className="gap-y-4">
      <PageTitle title="Параметры" />

      <ErrorUi
        onRefetch={refetch}
        isError={isError}
        title={`${showResponseStatusCode(error)}`}
      />

      <Loading isLoading={isLoading} title="Параметры" />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!snackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbar}
      />

      <DataTable
        searchPlaceholder="Поиск по названию"
        searchFieldId="Название"
        columns={dataColumns}
        data={params}
      />

      <Dialog open={drawer} onOpenChange={closeDrawer}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Редактирование параметра</DialogTitle>
          </DialogHeader>

          <EditParam
            loading={isUpdating}
            submit={submitEdit}
            param={param}
            setParam={setParam}
          />
        </DialogContent>
      </Dialog>
    </OHiddenWrapper>
  );
};

export default withLayout(ParamsPage);
