import dayjs, { type Dayjs } from 'dayjs';

export const getHoursFormat = (date: string) => {
  return dayjs(date).format('HH');
};

export const getFormatedDate = (
  date: Dayjs | Date | null | string,
  start = false,
  format = 'YYYY-MM-DD HH:mm:ss',
): string => {
  return dayjs(date)
    .second(start ? 0 : 59)
    .format(format);
};

export const getDate = (subtract: number = 0): Dayjs => {
  if (subtract) {
    return dayjs().subtract(subtract, 'day').hour(0).minute(0);
  }

  return dayjs();
};
