import { RxCaretSort } from 'react-icons/rx';
import { type Column } from '@tanstack/react-table';
import cn from 'classnames';
import { ArrowDownIcon, ArrowUpIcon } from 'lucide-react';

interface DataTableColumnHeaderProps<TData, TValue> {
  className?: string;
  column: Column<TData, TValue>;
  title: string;
}

export const DataTableColumnHeader = <TData, TValue>(
  props: DataTableColumnHeaderProps<TData, TValue>,
) => {
  const { className, column, title } = props;

  if (!column.getCanSort()) {
    return <div className={className}>{title}</div>;
  }

  console.log(column.getIsSorted());

  const handleSorting = () => {
    if (column.getIsSorted() === 'desc') {
      column.clearSorting();
      return;
    }

    if (column.getIsSorted() === 'asc') {
      column.toggleSorting(true);
      return;
    }

    if (!column.getIsSorted()) {
      column.toggleSorting(false);
    }
  };

  return (
    <div className={cn('flex items-center space-x-2', className)}>
      <div
        role="button"
        onClick={handleSorting}
        className="flex items-center cursor-pointer"
      >
        <span>{title}</span>

        {column.getIsSorted() === 'desc' ? (
          <ArrowDownIcon className="ml-2 h-4 w-4" />
        ) : column.getIsSorted() === 'asc' ? (
          <ArrowUpIcon className="ml-2 h-4 w-4" />
        ) : (
          <RxCaretSort className="ml-2 h-4 w-4" />
        )}
      </div>
    </div>
  );
};
