import React from 'react';

import { Dashboard } from '@/components/Dashboard/Dashboard';
import { PageTitle } from '@/components/PageTitle/PageTitle';
import { withLayout } from '@/layout/Layout';

const DashboardPage = () => {
  return (
    <div className="space-y-6">
      <PageTitle title="Дашборд" />
      <Dashboard />
    </div>
  );
};

export default withLayout(DashboardPage);
