import { type PrintJob } from '@/global/types';

import { api } from './api';

const printJobApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPrintJobs: builder.query<PrintJob[], void>({
      query: () => '/v2/job/get-report-all',
    }),
  }),
});

export const { useGetPrintJobsQuery } = printJobApi;
