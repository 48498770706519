import type {
  Role,
  UnrelatedUser,
  User,
  UserCreate,
  UserCreateRes,
} from '@/global/userShema';

import { api } from './api';

const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<User[], void>({
      query: () => '/user/list',
      providesTags: ['USER'],
    }),
    saveUser: builder.mutation<void, Omit<User, 'authorities'>>({
      query: (user) => ({
        url: '/user/save',
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['USER'],
    }),
    createUser: builder.mutation<UserCreateRes, UserCreate>({
      query: (user) => ({
        url: '/user/create',
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['USER'],
    }),
    getUserRoles: builder.query<Role[], void>({
      query: () => '/user/list-roles',
    }),
    linkUserWithAplerUser: builder.mutation<
      void,
      { userId: number; managerGUID: string }
    >({
      query: (link) => ({
        url: `/v2/user-accGUID/add?userId=${link.userId}&managerGUID=${link.managerGUID}`,
        method: 'POST',
      }),
    }),
    getAplerUserByUserId: builder.query<UnrelatedUser, number>({
      query: (userId) => ({
        url: `/v2/user-accGUID/get-empl-by-usrid?userId=${userId}`,
      }),
    }),
    getAplerUsers: builder.query<UnrelatedUser[], void>({
      query: () => '/v2/user-accGUID/unrelated-user',
    }),
  }),
});

export const {
  useGetUsersQuery,
  useSaveUserMutation,
  useGetUserRolesQuery,
  useLinkUserWithAplerUserMutation,
  useGetAplerUsersQuery,
  useLazyGetAplerUserByUserIdQuery,
  useCreateUserMutation,
} = userApi;
