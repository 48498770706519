import { Toaster } from '@/shared/ui/Toast/Toast';

import Routes from './routes/Routes';

function App() {
  return (
    <div className="App">
      <Routes />
      <Toaster />
    </div>
  );
}

export default App;
