import React from 'react';
import { Navigate } from 'react-router-dom';

import { useTypedSelector } from '../store/hooks/hooks';

interface PrivateRouteProps {
  children: React.ReactNode
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const isAuthenticated = useTypedSelector((state) => state.auth.authenticated);

  return isAuthenticated ? <>{children}</> : <Navigate to="/auth" replace />;
};

export default PrivateRoute;
