import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import cn from 'classnames';

import { type IPrinter } from '@/global/types';
import { Badge } from '@/shared/ui/Badge/Badge';

import { details } from './data';

import styles from './Info.module.css';

interface InfoProps {
  printer: IPrinter;
}

const deviceStatus = (state: number) => (state ? 'В сети' : 'Выкл.');
const cutString = (string: string) => {
  return string.length > 30 ? string.slice(0, 30) + ' ...' : string;
};

const Info: React.FC<InfoProps> = ({ printer }) => {
  const { name, state, id } = printer;

  return (
    <div className="flex flex-col gap-1 w-full">
      <div className="flex justify-between items-center">
        <Tooltip title={name} placement="top-start">
          <Link to={`/device/${id}`}>
            <h3
              className="text-foreground max-w-[160px]
              whitespace-nowrap overflow-hidden text-sm"
            >
              {cutString(name)}
            </h3>
          </Link>
        </Tooltip>

        <Badge
          variant="outline"
          className={cn(
            state ? 'text-green-600' : 'text-red-600',
            'text-[0.6rem]',
          )}
        >
          {deviceStatus(state)}
        </Badge>
      </div>

      {Object.keys(details).map((key) => (
        <div className="flex justify-between" key={key}>
          {details[key].map(({ cl, placement, tooltip, field }) => (
            <Tooltip key={tooltip} title={tooltip} placement={placement}>
              <span className="text-xs text-muted-foreground">
                {printer[field]}
              </span>
            </Tooltip>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Info;
