import React, { useEffect, useMemo } from 'react';

import { LineChartWidget } from '@/components/Dashboard/LineChartWidget/LineChartWidget';
import { PieChartWidget } from '@/components/Dashboard/PieChartWidget/PieChartWidget';
import { SimpleData } from '@/components/Dashboard/SimpleData/SimpleData';
import { WidgetCardHeader } from '@/components/Dashboard/WidgetCardHeader/WidgetCardHeader';
import {
  type WidgetShortInfo,
  type WidgetTypes,
} from '@/global/dashboard.interface';
import { Card, CardContent } from '@/shared/ui/Card/Card';
import { useGetWidgetsShortInfoQuery } from '@/store/queries/dashboardApi';

type Keys = Partial<Record<WidgetTypes, WidgetShortInfo[]>>;

const createNormalizedWidgetsShortInfo = (
  widgets: WidgetShortInfo[] | undefined,
): Keys => {
  if (!widgets) {
    return {};
  }

  const normalizedData: Keys = {};

  for (const widget of widgets) {
    const widgetType = widget.widgetType;

    if (!normalizedData[widgetType]) {
      normalizedData[widgetType] = [];
    }
    normalizedData[widgetType]?.push({
      ...widget,
      dataUrl: widget.dataUrl.replace('/api', ''),
    });
  }

  return normalizedData;
};

export const Dashboard = () => {
  const { data } = useGetWidgetsShortInfoQuery();

  const normalizedWidgetsShortInfo = useMemo(
    () => createNormalizedWidgetsShortInfo(data?.widgets),
    [data],
  );

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message.includes('ResizeObserver')) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-wrap gap-3 pb-5">
        {Object.values(normalizedWidgetsShortInfo).map((value) => (
          <>
            {value.map(({ dataUrl, name, widgetType, id }, i) => {
              if (widgetType === 'RING_DATAGRAM') {
                return (
                  <Card
                    key={dataUrl}
                    className="min-w-[350px] max-w-[350px] overflow-hidden"
                  >
                    <WidgetCardHeader title={name} type={id} />
                    <CardContent className="pt-4">
                      <PieChartWidget key={i} dataUrl={dataUrl} />
                    </CardContent>
                  </Card>
                );
              }

              if (widgetType === 'SIMPLE_DATA') {
                return (
                  <Card key={dataUrl} className="min-w-[270px] overflow-hidden">
                    <WidgetCardHeader title={name} type={id} />

                    <CardContent className="pt-4">
                      <SimpleData key={i} dataUrl={dataUrl} />
                    </CardContent>
                  </Card>
                );
              }

              if (widgetType === 'GRAPHIC_DATA') {
                return (
                  <Card
                    key={dataUrl}
                    className="w-[49.5%] max-[1470px]:w-[100%] overflow-hidden"
                  >
                    <WidgetCardHeader title={name} type={id} />
                    <CardContent className="pl-0 pt-4">
                      <LineChartWidget dataUrl={dataUrl} key={i} />
                    </CardContent>
                  </Card>
                );
              }

              return <></>;
            })}
          </>
        ))}
      </div>
    </div>
  );
};
