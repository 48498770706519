import { BrowserRouter,Route, Routes as Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import { authRoute, type IPageRoute, protectedPageRoutes } from '.';

const renderRoutes = (routes: IPageRoute[], protectRoute: boolean = true) => {
  return routes.map(({ component: Component, path }) =>
    protectRoute
      ? (
        <Route
          key={path}
          path={path}
          element={<PrivateRoute>{Component}</PrivateRoute>}
        />
      )
      : (
        <Route key={path} path={path} element={Component} />
      )
  );
};

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        {renderRoutes(protectedPageRoutes)}
        {renderRoutes(authRoute, false)}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
