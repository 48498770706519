import React from 'react';
import cn from 'classnames';

interface PageTitleProps {
  title: string;
  className?: string;
}

export const PageTitle = ({ title, className }: PageTitleProps) => {
  return <h3 className={cn('text-3xl font-bold', className)}>{title}</h3>;
};
