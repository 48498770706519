import React from 'react';
import { PulseLoader } from 'react-spinners';

import styles from './Loading.module.css';

interface LoadingProps {
  isLoading: boolean
  title: string
}

export const Loading: React.FC<LoadingProps> = ({ isLoading, title }) => {
  if (!isLoading) {
    return <></>;
  }

  return (
    <div className={styles.loading}>
      <h2>{title}</h2>
      <PulseLoader
        loading={true}
        color="gray"
        size={10}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};
