import React, { useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Auth } from '@/components';
import { useTypedSelector } from '@/store/hooks/hooks';

const AuthPage: React.FC = () => {
  const navigate = useNavigate();
  const authenticated = useTypedSelector((state) => state.auth.authenticated);
  const activeTheme = useTypedSelector((state) => state.theme);

  useLayoutEffect(() => {
    document.documentElement.dataset.theme = activeTheme;
  }, []);

  useEffect(() => {
    if (authenticated) navigate('/printers');
  }, [authenticated]);

  return (
    <div className="h-full w-full bg-background">
      <Auth />
    </div>
  );
};

export default AuthPage;
