import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip} from 'chart.js';

import { config, options } from './data';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export interface ChartProps {
  labels: string[]
  data: string[] | number[]
}

export const Chart: React.FC<ChartProps> = ({ labels, data }) => {
  return (
    <div
      style={{
        height: 280,
        position: 'relative',
        width: '100%'
      }}
    >
      <Line
        data={config({ labels, data })}
        options={options}
        style={{ position: 'absolute' }}
      />
    </div>
  );
};
