import {
  type Widget,
  type WidgetShortInfo,
} from '@/global/dashboard.interface';

import { api } from './api';

const dashboardApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWidgetsShortInfo: build.query<{ widgets: WidgetShortInfo[] }, void>({
      query: () => '/v2/dashboard/list',
    }),
    getWidgetDetails: build.query<Widget, string>({
      query: (url) => url,
    }),
  }),
});

export const { useGetWidgetsShortInfoQuery, useGetWidgetDetailsQuery } =
  dashboardApi;
