import { type ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import formatter from 'format-number';

import { DataTableColumnHeader } from '@/components/DataTable/DataTableColumnHeader';

import { type Order } from '../../global/types';

const numberFormat = formatter({ integerSeparator: ' ', round: 0 });

export const columns: Array<ColumnDef<Order>> = [
  {
    id: 'Номер заказа',
    header: 'Номер заказа',
    accessorKey: 'orderNumb',
  },
  {
    id: 'Клиент',
    header: 'Клиент',
    accessorKey: 'clientName',
  },
  {
    id: 'Продукция',
    header: 'Продукция',
    accessorKey: 'productName',
  },
  {
    id: 'Тираж',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Тираж" />
    ),
    accessorKey: 'baseRequestQuantity',
    cell: ({ row }) => (
      <span className="whitespace-nowrap">
        {numberFormat(row.getValue('Тираж'))}{' '}
      </span>
    ),
  },
  {
    id: 'Сумма',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Сумма" />
    ),
    accessorKey: 'sumPay',
    cell: ({ row }) => <span>{numberFormat(row.getValue('Сумма'))}</span>,
  },
  {
    id: 'Дата сдачи',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Дата сдачи" />
    ),
    accessorKey: 'finishPlanDate',
    cell: ({ row }) =>
      format(new Date(row.getValue('Дата сдачи')), 'dd.MM.yyyy'),
  },
  {
    id: 'Мендежер',
    header: 'Менеджер',
    accessorKey: 'emplFIO',
  },
];
