import { useCallback, useEffect, useState } from 'react';

type EmptyObject = Record<string, string>;

interface UseFilterCopyProps<Data extends any[]> {
  data: Data
  searchValue: string
}

// eslint-disable-next-line max-len
export const useFilter = <Data extends any[], FilterKeys extends string = string>({ data, searchValue }: UseFilterCopyProps<Data>) => {
  const [activeFilters, setActiveFilters] = useState<Record<FilterKeys, string> | EmptyObject>({});

  const [searchIsActive, setSearchIsActive] = useState<boolean>(false);
  const [filtered, setFiltered] = useState<Data | []>([]);

  useEffect(() => {
    if (data.length && filtered.length === 0) {
      setFiltered(data);
    }
  }, [data]);

  const [searchedData, setSearchedData] = useState<Data | []>([]);

  useEffect(() => {
    if (searchValue) {
      setSearchIsActive(true);
      handleSearch();
    } else {
      setSearchIsActive(false);
    }
  }, [searchValue]);

  const handleSearch = () => {
    const searched = data.filter((item) => {
      return Object.values(item)
        .join(' ')
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    });

    setSearchedData(searched as Data);
  };

  useEffect(() => {
    if (!Object.keys(activeFilters).length) {
      setFiltered(data);
    }
  }, [activeFilters]);

  // add filter to activeFilters
  const handleChange = useCallback((field: string, value: string) => {
    if (value === '') {
      const filtersCopy = { ...activeFilters };
      delete filtersCopy[field as FilterKeys];
      setActiveFilters(filtersCopy);
    } else {
      setActiveFilters({ ...activeFilters, [field]: value });
    }
  }, [activeFilters]);

  useEffect(() => {
    if (searchValue) {
      const searched = filtered.filter((item) => {
        return Object.values(item)
          .join(' ')
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });

      setSearchedData(searched as Data);
    } else {
      setSearchedData([]);
    }
  }, [searchValue]);

  // trigger and filter on activeFilters changes
  useEffect(() => {
    filterData();
  }, [activeFilters]);

  const filterData = () => {
    if (Object.keys(activeFilters).length) {
      const filtered = data.filter((elem) => {
        for (const key in activeFilters) {
          // if at least one value doesn't match = false
          if (
            String(elem[key]).toLowerCase() !==
            String(activeFilters[key as FilterKeys]).toLowerCase()) {
            return false;
          }
        }
        return true;
      });
      setFiltered(filtered as Data);
    }
  };

  const resetFilters = useCallback(() => {
    setActiveFilters({});
  }, []);

  return {
    activeFilters,
    filtered: searchIsActive ? searchedData : filtered,
    resetFilters,
    handleChange
  };
};
