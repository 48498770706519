import React from 'react';

import { Sidebar } from '@/components';

import type { LayoutProps } from './Layout.props';

interface LayoutOptions {}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="flex">
      <Sidebar />
      <div className="h-[100vh] overflow-y-scroll w-full max-[1200px]:pt-[35px]">
        <div className="flex-grow relative p-4 h-full bg-background">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;

interface IWithLayout {}
export function withLayout<T extends IWithLayout>(
  Component: React.ComponentType<T>,
  options: LayoutOptions = { paper: true },
) {
  return (props: T) => (
    <Layout>
      <Component {...props} />
    </Layout>
  );
}
