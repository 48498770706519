import React from 'react';
import cn from 'classnames';

import Info from './Info/Info';
import TonerList from './TonerList/TonerList';
import type { DeviceCardProps } from './Item.props';

const DeviceCard: React.FC<DeviceCardProps> = ({
  printer,
  className,
  ...props
}) => {
  return (
    <div className="h-full" {...props}>
      <div
        className={cn(
          'flex flex-col p-4 h-full gap-4 border-b border-l',
          className,
        )}
      >
        <Info printer={printer} />

        <TonerList colorsSupLevel={printer.colorsSupLevel} />
      </div>
    </div>
  );
};

export default React.memo(DeviceCard);
