import { type ReactNode } from 'react';
import cn from 'classnames';

interface OHiddenWrapperProps {
  children: ReactNode;
  className: string;
}

export const OHiddenWrapper = ({
  children,
  className,
}: OHiddenWrapperProps) => {
  return (
    <div className={cn('flex flex-col h-full overflow-y-hidden', className)}>
      {children}
    </div>
  );
};
