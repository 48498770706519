import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { type LucideIcon } from 'lucide-react';

interface SidebarItemProps {
  collapsed: boolean;
  path: string;
  title: string;
  icon: LucideIcon;
}

export const SidebarItem = ({
  path,
  collapsed,
  icon: Icon,
  title,
}: SidebarItemProps) => {
  const { pathname } = useLocation();

  const isActive = path === pathname;

  return (
    <li
      key={path}
      className={cn(
        'group hover:bg-gray-200 relative cursor-pointer rounded-xl hover:dark:bg-opacity-20',
        isActive && 'bg-gray-200 dark:bg-opacity-20',
      )}
    >
      <span
        className={cn(
          'absolute hidden top-1/2 whitespace-nowrap -translate-y-1/2 ' +
            'left-[100%] p-2 bg-gray-200 rounded-r-md',
          collapsed && 'group-hover:flex',
        )}
      >
        {title}
      </span>

      <Link
        className="flex items-center text-foreground p-4 gap-3 text-sm font-medium"
        to={path}
      >
        <Icon className={`w-5 h-5 ${isActive ? 'stroke-blue-400' : ''}`} />

        <p className={cn('whitespace-nowrap', collapsed && 'hidden')}>
          {title}
        </p>
      </Link>
    </li>
  );
};
