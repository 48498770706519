import { type ColumnDef } from '@tanstack/react-table';

import { type IQueue } from '@/global/types';

export const columns: Array<ColumnDef<IQueue>> = [
  {
    accessorKey: 'queueName',
    header: 'Имя очереди',
  },
  {
    accessorKey: 'counter',
    header: 'Счетчик',
  },
  {
    accessorKey: 'direct',
    header: 'Доступность',
  },
  {
    accessorKey: 'printer',
    header: 'Принтер',
  },
  {
    accessorKey: 'active',
    header: 'Активность',
  },
  {
    accessorKey: 'type',
    header: 'Тип очереди',
  }
];
