import React from 'react';
import { AlertCircle } from 'lucide-react';

import { Button } from '@/shared/ui/Button/Button';

import styles from './ErrorUi.module.css';

interface ErrorUiProps {
  isError: boolean;
  title: string;
  onRefetch?: () => void;
}

export const ErrorUi: React.FC<ErrorUiProps> = ({
  isError,
  title,
  onRefetch,
}) => {
  if (!isError) {
    return <></>;
  }

  return (
    <div className={`${styles.error} space-y-2`}>
      <AlertCircle className="w-[100px] h-[100px] text-destructive" />
      <h2>{title}</h2>

      {onRefetch && <Button onClick={onRefetch}>Обновить</Button>}
    </div>
  );
};
