import React from 'react';
import { Tooltip } from '@mui/material';
import cn from 'classnames';

import { Button } from '@/shared/ui/Button/Button';

import { views } from './data';
import type { ViewProps } from './View.props';

import styles from './View.module.css';

const View: React.FC<ViewProps> = ({ view, changeView }) => {
  return (
    <div className={styles.views}>
      Вид:
      <div className="ml-1 space-x-1">
        {views.map(({ variant, title, icon: Icon }) => (
          <Tooltip title={title} key={variant}>
            <Button
              onClick={() => {
                changeView(variant);
              }}
              className="w-[35px] h-[35px]"
              variant="outline"
              size="icon"
            >
              <Icon
                className={cn(
                  'w-5 h-5 text-muted-foreground cursor-pointer',
                  view === variant && 'stroke-blue-400',
                )}
              />
            </Button>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default React.memo(View);
