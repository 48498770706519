import { Link } from 'react-router-dom';
import { type ColumnDef } from '@tanstack/react-table';

import { type IPrinter } from '@/global/types';
import { Badge } from '@/shared/ui/Badge/Badge';

export const columns: Array<ColumnDef<IPrinter>> = [
  {
    accessorKey: 'name',
    header: 'Аппарат',
    cell: ({ row }) => (
      <Link to={`/device/${row.original.id}`}>{row.getValue('name')}</Link>
    ),
  },
  {
    accessorKey: 'state',
    header: 'Статус',
    cell: ({ row }) =>
      row.getValue('state') ? (
        <Badge variant="outline" className="text-green-600 whitespace-nowrap">
          в сети
        </Badge>
      ) : (
        <Badge variant="outline" className="text-red-600 whitespace-nowrap">
          выкл.
        </Badge>
      ),
  },
  {
    accessorKey: 'ip',
    header: 'IP',
    cell: ({ row }) => <Badge variant="outline">{row.getValue('ip')}</Badge>,
    enableHiding: false,
  },
  {
    accessorKey: 'sn',
    header: 'Серийный номер',
  },
  {
    accessorKey: 'counter',
    header: () => <span className="whitespace-nowrap">Текущий счетчик</span>,
  },
];
