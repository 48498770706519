import React from 'react';
import { Tooltip } from '@mui/material';
import cn from 'classnames';

import type { ISupplies, PrinterColorTypes } from '@/global/types';

import styles from './TonerList.module.css';

const colors = {
  YELLOW: 'Желтый',
  CYAN: 'Голубой',
  MAGENTA: 'Пурпурный',
  CLEAR: 'Лак',
  BLACK: 'Черный',
  WHITE: 'Белый',
};

interface TonerListProps {
  colorsSupLevel: ISupplies;
}

const colorMap: Record<PrinterColorTypes, string> = {
  BLACK: 'bg-gray-600',
  WHITE: 'bg-gray-100',
  YELLOW: 'bg-yellow-400',
  CYAN: 'bg-blue-400',
  CLEAR: 'bg-green',
  MAGENTA: 'bg-fuchsia-400',
};

const colorMapDark: Record<PrinterColorTypes, string> = {
  BLACK: 'dark:bg-gray-800',
  WHITE: 'dark:bg-gray-400',
  YELLOW: 'dark:bg-yellow-500 opacity-80',
  CYAN: 'dark:bg-blue-500',
  CLEAR: 'dark:bg-green',
  MAGENTA: 'dark:bg-fuchsia-600',
};

const TonerList: React.FC<TonerListProps> = ({ colorsSupLevel }) => {
  return (
    <div className={styles.toners}>
      {Object.values(colorsSupLevel)
        // color sort
        .sort((a, b) => (a.color > b.color ? 1 : b.color > a.color ? -1 : 0))
        .map((level) => (
          <div key={level.color} className={styles.tonerBlock}>
            <p className="text-muted-foreground text-xs w-[80px]">
              {colors[level.color]}
            </p>

            <Tooltip title={`${level.val}%`} placement="right">
              <span
                className={cn(
                  styles.toner,
                  'block h-[13px] w-[150px] border rounded-lg overflow-hidden',
                  level.val < 20 && 'border-red-400 dark:border-opacity-40',
                )}
              >
                <span
                  className={`${colorMap[level.color]} ${
                    colorMapDark[level.color]
                  } border-r block h-full`}
                  style={{ width: `${level.val}%` }}
                ></span>
              </span>
            </Tooltip>
          </div>
        ))}
    </div>
  );
};

export default TonerList;
