import React from 'react';
import nextId from 'react-id-generator';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/shared/ui/Table/Table';

import type { TableProps } from './Table.props';

const MyTable: React.FC<TableProps> = ({
  columns,
  data,
  height,
  direction = 'horizontal',
  ...props
}) => {
  return (
    <div {...props}>
      <Table className="text-sm">
        {direction === 'horizontal' && (
          <TableHeader className="sticky w-full top-0 bg-background shadow-sm">
            <TableRow>
              {columns.map(({ title, headStyle }) => (
                <TableHead
                  className="px-3 py-3 whitespace-nowrap"
                  key={nextId()}
                  style={{ ...headStyle }}
                >
                  {title}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
        )}
        <TableBody>
          {direction === 'horizontal' &&
            data.map((row: any) => (
              <TableRow key={nextId()}>
                {columns.map(({ field, Cell, style }) => (
                  <TableCell key={nextId()} style={{ ...style }}>
                    {Cell ? Cell(row) : row[field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}

          {direction === 'vertical' &&
            columns.map(({ field, title, Cell, style }) => (
              <TableRow key={nextId()}>
                <TableCell className="p-3 text-muted-foreground font-medium">
                  {title}
                </TableCell>
                {data.map((row: any) => (
                  <TableCell
                    className="p-3"
                    key={nextId()}
                    style={{ ...style }}
                  >
                    {Cell ? Cell(row) : row[field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default React.memo(MyTable);
