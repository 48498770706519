import { useEffect, useState } from 'react';

import { type LineChartData } from '@/components/LineChart/LineChart';
import { getFormatedDate, getHoursFormat } from '@/helpers/date';
import {
  useLazyGetAlertsQuery,
  useLazyGetChartQuery,
} from '@/store/queries/printerApi';

export const useChart = (
  id: string | undefined,
  dateEnd: Date | null | undefined,
  dateStart: Date | null | undefined,
) => {
  const [getAlerts, { data: alerts = [] }] = useLazyGetAlertsQuery();

  const [chartData, setChartData] = useState<LineChartData[]>([]);

  const [getChart, { data: chart = [], isLoading: chartLoading }] =
    useLazyGetChartQuery();

  useEffect(() => {
    if (chart.length) {
      const chartDataL: LineChartData[] = [];

      chart.forEach(({ timeSetValue, counterValue }, i, array) => {
        if (i !== 0) {
          if (
            getHoursFormat(array[i - 1].timeSetValue) !==
            getHoursFormat(timeSetValue)
          ) {
            chartDataL.push({ name: timeSetValue, value: counterValue });
          }
        } else {
          chartDataL.push({ name: timeSetValue, value: counterValue });
        }
      });

      setChartData(chartDataL);

      //  aggregation (14:35, 14:55, 14:59) = 14:35
    }
  }, [chart]);

  const getChartAndAlerts = () => {
    if (id && dateEnd && dateStart) {
      setChartData([]);

      const formattedDateStart = getFormatedDate(dateStart, true);
      const formattedDateEnd = getFormatedDate(dateEnd);

      getChart({
        id,
        dateEnd: formattedDateEnd,
        dateStart: formattedDateStart,
      });
      getAlerts({
        id,
        dateEnd: formattedDateEnd,
        dateStart: formattedDateStart,
      });
    }
  };

  return {
    alerts,
    chart,
    chartData,

    chartLoading,
    getChartAndAlerts,
  };
};
