import React, { type ChangeEvent, useEffect, useState } from 'react';

import { Input } from '@/shared/ui/Input/Input';

import { type SearchProps } from './Search.props';

export const Search: React.FC<SearchProps> = ({ setSearch }) => {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearch(value);
    }, 400);

    return () => {
      clearTimeout(timeout);
    };
  }, [value]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };

  return (
    <Input
      className="min-w-[200px]"
      placeholder="Поиск"
      onChange={handleSearch}
      value={value}
    />
  );
};
