import React from 'react';
import { Navigate } from 'react-router-dom';

import {
  AuthPage,
  OrdersPage,
  ParamsPage,
  PrinterPage,
  PrintersPage,
  UsersPage,
} from '@/pages';

import DashboardPage from '../pages/DashboardPage/DashboardPage';
import PrinterJobsPage from '../pages/PrintJobsPage/PrinterJobsPage';
import { Bolt } from 'lucide-react';
import QueuePage from '@/pages/QueuePage/QueuePage';

export interface IPageRoute {
  title: string;
  path: string;
  component?: React.ReactNode;
}

export const protectedPageRoutes: IPageRoute[] = [
  {
    title: 'Дашборд',
    path: '/dashboard',
    component: <DashboardPage />,
  },
  {
    title: 'Принтеры',
    path: '/',
    component: <Navigate to="/dashboard" />,
  },
  {
    title: 'Принтеры',
    path: '/printers',
    component: <PrintersPage />,
  },
  {
    title: 'Устройство',
    path: '/device/:id',
    component: <PrinterPage />,
  },
  {
    title: 'Параметры',
    path: '/params',
    component: <ParamsPage />,
  },

  { title: 'Очередь',
    path: '/queues',
    component: <QueuePage />,
  },

  {
    title: 'Работы печати',
    path: '/print-jobs',
    component: <PrinterJobsPage />,
  },
  {
    title: 'Заказы',
    path: '/orders',
    component: <OrdersPage />,
  },
  {
    title: 'Пользователи',
    path: '/users',
    component: <UsersPage />,
  },
];

export const authRoute: IPageRoute[] = [
  {
    title: 'Авторизация',
    path: '/auth',
    component: <AuthPage />,
  },
];
